import { Row, Col } from "antd";
import useAppContext from "../../Hooks/useAppContext";

const VERSION = "3.2.9";

const Footer = () => {
  const { serverVersion } = useAppContext();

  return (
    <footer style={{ paddingBlock: 10 }}>
      <Row justify="center">
        <Col style={{ textAlign: "center" }}>
          <p>
            <sup>Version: {VERSION}</sup>
          </p>
          <p>
            <sup>Server Version: {serverVersion}</sup>
          </p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
