import React from "react";
import { Droppable } from "@hello-pangea/dnd";

const COLUMN_ID_DONE = "done";

export const DroppableTableBody: React.FC<{
  columnId: string;
  className?: string;
}> = ({ columnId, ...props }) => (
  <Droppable
    droppableId={columnId}
    type="group"
  >
    {(provided, snapshot) => (
      <tbody
        ref={provided.innerRef}
        {...props}
        {...provided.droppableProps}
        className={`${props.className} ${
          snapshot.isDraggingOver && columnId === COLUMN_ID_DONE
            ? "is-dragging-over"
            : ""
        }`}
      ></tbody>
    )}
  </Droppable>
);