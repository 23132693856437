import React, { useContext, useState, createContext } from "react";
import { Entities, Node } from "./types";
import { entitiesUpdate } from "./Components/utils";
import { initialEntities } from "./Components/Config";

interface ContextType {
  entities: Entities;
  selectedNodeIds: string[];
  setEntities: React.Dispatch<React.SetStateAction<Entities>>;
  setSelectedNodeIds: React.Dispatch<React.SetStateAction<string[]>>;
  addNewCat: (record: Node) => void;
  handleOnNodeSelect: (record: Node) => void;
  selectedTreePath: (record: Node) => void;
}

const CountContext = createContext<ContextType | undefined>(undefined);


const TreeProvider = ({ children }: { children: React.ReactNode }) => {
  const [entities, setEntities] = useState<Entities>(initialEntities);
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([]);

  const selectedTreePath = (record: Node) => {
    setSelectedNodeIds((prevState) => {
      const currentCatNumber = Number(record.columnId.charAt(3));

      // Filter out node IDs that belong to higher CATs
      const filteredNodeIds = prevState.filter((nodeId) => {
        const node = entities.nodes.find((node) => node.id === nodeId);
        if (!node || !node.columnId) return false; // Ensure node and columnId exist
        const nodeCatNumber = Number(node.columnId.charAt(3));
        return nodeCatNumber < currentCatNumber; // Retain nodes from lower CATs
      });

      // Add the new record ID
      return [...filteredNodeIds, record.id];
    });
  };

  const handleOnNodeSelect = (record: Node) => {
    const currentCatNumber = Number(record.columnId.charAt(3));
    if (currentCatNumber < 4) {
      const newCatNumber = currentCatNumber + 1;
      const catTo = `CAT${newCatNumber}`;
      const updatedEntities = entitiesUpdate(entities, record.options, catTo);
      selectedTreePath(record);
      setEntities(updatedEntities);
    }
  };

  const addNewCat = (record: Node) => {
    const currentCatNumber = Number(record.columnId.charAt(3));
    if (currentCatNumber < 4) {
      const newCatNumber = currentCatNumber + 1;
      const catTo = `CAT${newCatNumber}`;

      // Generate a unique ID for the new node
      const newNodeId = crypto.randomUUID();

      // Temp logic
      const newNode: Node = {
        id: newNodeId,
        name: "new node",
        options: [],
        columnId: catTo,
      };

      const newColumns = {
        ...entities.columns,
        [catTo]: {
          ...entities.columns[catTo],
          nodeIds: [...(entities.columns[catTo]?.nodeIds || []), newNodeId],
        },
      };

      const newEntities: Entities = {
        ...entities,
        nodes: [...entities.nodes, newNode],
        columns: newColumns,
      };

      setEntities(newEntities);
    }
  };

  // Memoize the context value to prevent unnecessary re-renders
  const value = React.useMemo(
    () => ({ entities, selectedNodeIds, setSelectedNodeIds, setEntities, addNewCat, handleOnNodeSelect, selectedTreePath }),
    [entities, selectedNodeIds],
  );
  return <CountContext.Provider value={value}>{children}</CountContext.Provider>;
};

const useTree = () => {
  const context = useContext(CountContext);
  if (context === undefined) {
    throw new Error("useTree must be used within a TreeProvider");
  }
  return context;
};

export { TreeProvider, useTree };
