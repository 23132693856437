import type { ColumnsType } from "antd/es/table";
import { Column, Entities, Node } from "../types";
import { Cell } from "./Cell";
import React from "react";

export const columnIds: string[] = ["CAT1", "CAT2", "CAT3", "CAT4"];
export const columns: Record<string, Column> = {
    CAT1: { id: "CAT1", title: "Category 1", nodeIds: [] },
    CAT2: { id: "CAT2", title: "Category 2", nodeIds: [] },
    CAT3: { id: "CAT3", title: "Category 3", nodeIds: [] },
    CAT4: { id: "CAT4", title: "Category 4", nodeIds: [] },
  };

export const initialEntities: Entities = {
  nodes: [],
  columnIds,
  columns,
};

export const tableColumns: ColumnsType<Node> = [
  {
    title: '',
    dataIndex: "id",
    render: (text, record) => <Cell record={record} />,
  },
];

export const ValidityAreas = [
  { label: 'Local', value: 'LOCAL' },
  { label: 'National', value: 'NATIONAL' },
  { label: 'Calculatoric conditions', value: 'CALCULATORIC_CONDITIONS' },
  { label: 'Trade marketing', value: 'TRADEMARKETING' },
  { label: 'Retail media international', value: 'RETAIL_MEDIA_INTERNATIONAL' },
];

export const countries = [
  { label: 'Germany', value: 'DE' },
  { label: 'Spain', value: 'ES' },
];
