import styles from "../User/EditUser/UserEdit.module.scss";
import { Col, Divider, PageHeader, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Tree } from "./Components/Tree";
import { Fieldset } from "../../Components/Fieldset/Fieldset";
import { countries, ValidityAreas } from "./Components/Config";
import { useTree } from "./TreeContext";
import { useGetConditionTree } from "./Hooks/useGetConditionTree";
import { useEffect, useState } from "react";

export const ConditionTreeContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [selectedCountry, setSelectedCountry] = useState<string>(countries[0].value);
  const [selectedArea, setSelectedArea] = useState<string>(ValidityAreas[0].value);
  const { setEntities } = useTree();
  const treeData = useGetConditionTree(selectedCountry, selectedArea);

  useEffect(() => {
    if (treeData) {
      setEntities(treeData);
    }
  }, [treeData]);

  const handleSelectCountry = (country: any) => {
    setSelectedCountry(country);
  };

  const handleSelectArea = (area: any) => {
    setSelectedArea(area);
  };

  return (
    <div className={styles.formContainer}>
      <PageHeader
        className={styles.container}
        title={t("conditionTree.title")}
        subTitle={t("conditionTree.subTitle")}
        data-testid="conditionTree-pageHeader-container"
        onBack={() => navigate("/users-list", { state: state })}
      />
      <Fieldset title={t("conditionTree.title")}>
        <Row gutter={12}>
          <Col className="gutter-row" span={4}>
            <Select style={{ width: "100%" }} options={countries} defaultValue={countries[0]}
                    onChange={handleSelectCountry} />
          </Col>
          <Col className="gutter-row" span={4}>
            <Select style={{ width: "100%" }} options={ValidityAreas} defaultValue={ValidityAreas[0]}
                    onChange={handleSelectArea} />
          </Col>
        </Row>
        <Divider />
        <Tree />
      </Fieldset>
    </div>
  );
};
