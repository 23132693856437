import { TableProps } from "antd";
import { FilterValue } from "antd/lib/table/interface";

export interface UserType {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  userRights: UserRightsTypes[];
}

export interface UserRightsTypes {
  clusterId: string | number;
  countryId: string;
  manufacturerId: string | number;
  role: string;
  salesChannelId: string | number;
  storeId: string | number;
  supplierId: string | number;
  supplier: {
    countryId: Country;
    id: number;
    name: string;
    supplierShortId: number;
  };
  validityArea: string;
  namespace: string;
}

export interface TableUserDataType extends UserType {
  uniqueValidityAreas: string[];
  uniqueRoles: string[];
  uniqueCountries: string[];
  uniqueContractPartners: (UserRightsTypes["supplier"] | undefined)[];
}

export interface UserListResponse {
  data: TableUserDataType[];
  total: number;
}

export interface ContractPartnerListResponse {
  data: ContractPartnerData[];
  total: number;
}

export type OrderBy = string;

export interface Paging {
  page: number;
  pageSize: number;
  orderBy: OrderBy[];
}

export enum Country {
  DE = "DE",
  ES = "ES",
  BE = "BE",
  NL = "NL",
  LU = "LU",
}

export enum UserRole {
  QA = "QA",
  CATMAN = "CATMAN",
  READONLY = "READONLY",
  ACCOUNTING = "ACCOUNTING",
  IP = "IP",
  STORE = "STORE",
  USERADMIN = "USERADMIN",
  SUPERUSERADMIN = "SUPERUSERADMIN",
  BUSINESSADMIN = "BUSINESSADMIN",
  MAINTENANCE = "MAINTENANCE",
}

export enum ValidtyArea {
  LOCAL = "LOCAL",
  NATIONAL = "NATIONAL",
  TRADEMARKETING = "TRADEMARKETING",
  RETAIL_MEDIA_INTERNATIONAL = "RETAIL_MEDIA_INTERNATIONAL",
  CALCULATORIC_CONDITIONS = "CALCULATORIC_CONDITIONS",
  "*" = "*",
}

export interface Sorter {
  order: SortOrder;
  columnKey: string;
}

export type OnChange = NonNullable<TableProps<TableUserDataType>["onChange"]>;

export type Filters = Parameters<OnChange>[1];

export type SortOrder = "descend" | "ascend" | null;

export interface TableContractPartnerDataType extends ContractPartnerData {
  purchaseSections: PurchaseSection["name"][];
}

export interface EditContractPartnerDataType extends ContractPartnerData {
  purchaseSections: SelectOptionsType[];
}

export interface SelectOptionsType {
  label: string;
  value: string;
  countryId?: string;
}

export interface TableFilterTypes {
  text: string;
  value: string;
}

export interface ContractPartnerData {
  communication: string;
  createdAt: string;
  createdBy: string;
  isActive: boolean;
  hasSpecialPop: boolean;
  id: number;
  isOnboarded: boolean;
  isConditionApprovalBlacklisted: boolean;
  isPopApprovalBlacklisted: boolean;
  onboardedPurchaseSections: PurchaseSection[];
  supplier: SupplierData;
  updatedAt: string;
  updatedBy: string;
  version?: number;
  ipDurationDays: number | null;
  popDurationDays: number | null;
  specialSettlementCompanyCode: string | null;
  settlementType: string | null;
  customerReturnsIncluded: boolean;
  recalcOptionEnabled: boolean;
  recalcDurationDays: number | null;
}

export interface PurchaseSection {
  id: string | null;
  name: string;
  countryId?: string;
}

export interface SupplierData {
  id: string | number;
  countryId: string;
  name: string;
  supplierShortId: number;
}

export interface UserListFilter {
  countryId: Country[];
  role: UserRole[];
  validityArea: string[];
  firstname: string;
  lastname: string;
  username: string;
  email: string;
}

type FilterFieldName =
  | keyof UserListFilter
  | keyof ContractPartnerData
  | keyof SupplierData
  | "supplierName"
  | "supplierShortId"
  | "supplierId"
  | "purchase_section_name"
  | "purchase_section_abbreviation";

type FilterFieldWithValue = {
  name: FilterFieldName;
  op: "In" | "NotIn" | "Gte" | "Lte" | "Btw" | "Eq" | "Ne" | "Like" | "StartsWith" | "Intersection";
  value: (string | number)[] | FilterValue;
};
type FilterFieldWithoutValue = {
  name: FilterFieldName;
  op: "IsTrue" | "IsNotTrue" | "IsFalse" | "IsNotFalse" | "IsNull" | "IsNotNull";
  value: [];
};
export type FilterField = FilterFieldWithValue | FilterFieldWithoutValue;

export type WorkItemsFilterRequest = {
  filter: Array<FilterField[]>;
  countOnly?: true;
};
