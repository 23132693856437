import { Result, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import NotAuthorized from "./Components/NotAuthorized/NotAuthorized";
import { resolver, serviceUrl } from "./Config/serviceConfig";
import UserCreate from "./Pages/User/CreateUser/UserCreate";
import UserEdit from "./Pages/User/EditUser/UserEdit";
import UsersList from "./Pages/UsersList/UsersList";
import ContractPartnersList from "./Pages/ContractPartners/List/ContractPartnersList";
import AppContext from "./State/context/appContext";
import { UserRightsTypes } from "./types";
import { doesHttpOnlyCookieExist } from "./utils/basic";
import Layout from "./Components/Layout/Layout";
import ContractPartnerEdit from "./Pages/ContractPartners/Edit/ContractPartnerEdit";
import ContractPartnerCreate from "./Pages/ContractPartners/Create/ContractPartnerCreate";
import { useState } from "react";
import { ConditionTree } from "./Pages/ConditionTree/ConditionTree";

const userDataUrl = serviceUrl.getUserData();

const App: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [serverVersion, setServerVersion] = useState("");
  const checkUserRole = (role: string) => userData?.userRights.some((rights: UserRightsTypes) => rights.role === role);

  if (!doesHttpOnlyCookieExist("user_session") && process.env.NODE_ENV !== "development" && process.env.CI !== "true") {
    window.location.href = `${process.env.REACT_APP_DOMAIN}/login`;
  }

  const {
    data: userData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: userDataUrl,
    queryFn: () => resolver.query(userDataUrl, setServerVersion),
  });

  if (isLoading) {
    return <Spin size="large" />;
  }

  const isUserAdmin = checkUserRole("USERADMIN");
  const isSuperUserAdmin = checkUserRole("SUPERUSERADMIN");
  const isBusinessAdmin = checkUserRole("BUSINESSADMIN");

  const accessibleRoutes = [
    { path: "/users-list", isAuthenticated: isSuperUserAdmin || isUserAdmin, component: UsersList },
    { path: "/user/create", isAuthenticated: isSuperUserAdmin || isUserAdmin, component: UserCreate },
    { path: "/user/edit/:id", isAuthenticated: isSuperUserAdmin || isUserAdmin, component: UserEdit },
    { path: "/contract-partners/list", isAuthenticated: isBusinessAdmin, component: ContractPartnersList },
    { path: "/contract-partners/create", isAuthenticated: isBusinessAdmin, component: ContractPartnerCreate },
    { path: "/contract-partners/edit/:id", isAuthenticated: isBusinessAdmin, component: ContractPartnerEdit },
    { path: "/condition-tree", isAuthenticated: isBusinessAdmin, component: ConditionTree },
  ];

  const firstAccessibleRoute = accessibleRoutes.find((route) => route.isAuthenticated);
  const redirectTo = firstAccessibleRoute ? firstAccessibleRoute.path : "/not-authorized";

  if (isError && location.pathname !== "/not-authorized" && location.pathname !== "/error") {
    navigate("/error");
  }

  return (
    <>
      <AppContext.Provider value={{ user: { ...userData }, isSuperUserAdmin, isBusinessAdmin, serverVersion }}>
        <Layout>
          <Routes>
            {accessibleRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <PrivateRoute
                    redirect={redirectTo}
                    isAuthenticated={route.isAuthenticated}
                    Component={route.component}
                  />
                }
              />
            ))}
            <Route path="/not-authorized" element={<NotAuthorized />} />
            <Route path="/error" element={<Result status="warning" title={t("app.error")} />} />
            <Route path="/*" element={<Navigate to={redirectTo} />} />
          </Routes>
        </Layout>
      </AppContext.Provider>
    </>
  );
};
export default App;

const PrivateRoute = ({
  Component,
  isAuthenticated,
  redirect,
}: {
  Component: React.ComponentType;
  isAuthenticated: boolean;
  redirect: string;
}) => (isAuthenticated ? <Component /> : <Navigate to={redirect} />);
