import React from "react";
import { useQuery } from "react-query";
import { Column, Entities, MainDataNode, Node } from "../types";
import { columnIds as configColumnIds } from "../Components/Config";
import { resolver } from "../../../Config/serviceConfig";

export const useGetConditionTree = (countryId: string, validityArea: string): Entities => {
  const { data, isLoading, isError } = useQuery(
    ["conditionTree", countryId, validityArea],
    () => resolver.fetchConditionTree(countryId, validityArea),
    {
      enabled: !!countryId && !!validityArea,
    },
  );

  const defaultEntities: Entities = React.useMemo(
    () => ({
      nodes: [],
      columnIds: configColumnIds,
      columns: {
        CAT1: { id: "CAT1", title: "Category 1", nodeIds: [] },
        CAT2: { id: "CAT2", title: "Category 2", nodeIds: [] },
        CAT3: { id: "CAT3", title: "Category 3", nodeIds: [] },
        CAT4: { id: "CAT4", title: "Category 4", nodeIds: [] },
      },
    }),
    [],
  );

  return React.useMemo(() => {
    if (data && !isLoading && !isError) {
      return mapToEntitiesFirstLevel(data.data);
    }
    return defaultEntities;
  }, [data, isLoading, isError, defaultEntities]);
};

/**
 * Processes only the first level of items to create the Entities object.
 */
export const mapToEntitiesFirstLevel = (data: MainDataNode[]): Entities => {
  const nodes: Node[] = [];
  const columns: Record<string, Column> = {
    CAT1: { id: 'CAT1', title: 'Category 1', nodeIds: [] },
    CAT2: { id: 'CAT2', title: 'Category 2', nodeIds: [] },
    CAT3: { id: 'CAT3', title: 'Category 3', nodeIds: [] },
    CAT4: { id: 'CAT4', title: 'Category 4', nodeIds: [] },
  };
  const columnIds = configColumnIds;

  // Process only the first level of items
  for (const node of data) {
    if (node.items) {
      for (const item of node.items) {
        const taskId = crypto.randomUUID();
        const taskName = item.name;
        const options = item.options ? item.options : [];

        // Add the task to the tasks list
        nodes.push({ id: taskId, name: taskName, columnId: columnIds[0], options });

        // Determine the column based on the depth (assume first level = CAT1)
        const columnId = columnIds[0]; // Always CAT1 for first-level items
        if (columnId) {
          columns[columnId].nodeIds.push(taskId);
        }
      }
    }
  }

  return {
    nodes,
    columnIds,
    columns,
  };
};
