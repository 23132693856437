import React, { useState } from "react";
import { Table, Row, Col } from "antd";
import { DragDropContext, DropResult, BeforeCapture } from "@hello-pangea/dnd";
import { reorderSingleDrag } from "./utils";
import { DroppableTableBody } from "./DroppableTableBody";
import { DraggableTableRow } from "./DraggableTableRow";
import { useTree } from "../TreeContext";
import { Node, Entities } from "../types";
import { tableColumns } from "./Config";
import "./style.css";


export const Tree: React.FC = () => {
  const { entities, setEntities, handleOnNodeSelect, selectedNodeIds, setSelectedNodeIds, selectedTreePath } = useTree();
  const [draggingNodeId, setDraggingNodeId] = useState<string | null>(null);

  const getNodes = (entities: Entities, id: string): Node[] =>
    entities.columns[id].nodeIds.map(
      (nodeId) => entities.nodes.find((node) => node.id === nodeId) as Node
    );

  const findColumnIdForNode = (taskId: string): string | undefined => {
    return Object.entries(entities.columns).find(([_, column]) =>
      column.nodeIds.includes(taskId)
    )?.[0];
  };

  const onBeforeCapture = (start: BeforeCapture) => {
    const draggableId = start.draggableId;
    const selected = selectedNodeIds.includes(draggableId);
    if (!selected) setSelectedNodeIds([]);
    setDraggingNodeId(draggableId);
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source, reason } = result;
    if (!destination || reason === "CANCEL") {
      setDraggingNodeId(null);
      return;
    }

    const processed = reorderSingleDrag({
      entities,
      selectedNodeIds,
      source,
      destination,
    });

    setEntities(processed.entities);
    setDraggingNodeId(null);
  };

  const onClickRow = (e: React.MouseEvent, record: Node) => {
    e.stopPropagation();
    handleOnNodeSelect(record);
    selectedTreePath(record);
  };

  return (
    <div className={"c-multi-drag-table"}>
      <DragDropContext onBeforeCapture={onBeforeCapture} onDragEnd={onDragEnd}>
        <Row gutter={40}>
          {entities.columnIds.map((id) => {
            const isHighlighted = draggingNodeId && findColumnIdForNode(draggingNodeId) === id;
            return (
              <Col key={id} xs={6}>
                <div className="inner-col">
                  <Row justify="space-between" align="middle">
                    <h2>{entities.columns[id].title}</h2>
                  </Row>
                  <Table
                    className={isHighlighted ? "highlight-table" : ""}
                    dataSource={getNodes(entities, id)}
                    columns={tableColumns}
                    rowKey="id"
                    pagination={false}
                    showHeader={false}
                    components={{
                      body: {
                        wrapper: (val: any) => <DroppableTableBody columnId={entities.columns[id].id} {...val} />,
                        row: (val: any) => (
                          <DraggableTableRow
                            selectedNodeIds={selectedNodeIds}
                            draggingNodeId={draggingNodeId}
                            nodes={getNodes(entities, id)}
                            {...val}
                          />
                        ),
                      },
                    }}
                    onRow={(record, index) => ({
                      index,
                      record,
                      onClick: (e: React.MouseEvent) => onClickRow(e, record),
                    })}
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      </DragDropContext>
    </div>
  );
};
